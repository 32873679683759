import React from 'react';
import logo from '../../../images/logo.png';

function Footer() {
    return (
        <div id="rs-footer" className="rs-footer style4">
            <div className="footer-container" style={{ width: '90%', margin: 'auto' }}>
                <div className="footer-content pt-62 pb-79 md-pb-64 sm-pt-48">
                    <div className="row align-items-center">
                        {/* Logo Section */}
                        <div className="col-lg-4 col-md-12 col-sm-12 footer-widget text-center md-mb-39">
                            <div className="about-widget">
                                <div className="logo-part mb-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <a href="index.html">
                                        <img src={logo} alt="Footer Logo" style={{ width: '150px', height: 'auto' }} />
                                    </a>
                                </div>
                                <p className="desc">Achieve your goals with GTech Solutions, a leading software development agency specializing in innovative mobile app development, 
                                    SEO services, and custom software solutions.</p>
                            </div>
                        </div>

                        {/* Email and Social Media Section */}
                        <div className="col-lg-4 col-md-12 col-sm-12 footer-widget text-center md-mb-32">
                            <h4 className="widget-title">Get In Touch</h4>
                            <div className="email-widget mb-3">
                                <i className="flaticon-email"></i>
                                <div className="desc">
                                    <a href="mailto:ganeshtechnologysolutions@gmail.com" className="footer-email">ganeshtechnologysolutions@gmail.com</a>
                                </div>
                            </div>

                            {/* Social Media Icons */}
                            <div className="social-icons mt-3">
                                <ul className="footer-social" style={{ listStyle: 'none', padding: '0', margin: '0', display: 'flex', justifyContent: 'center' }}>
                                    <li style={{ margin: '0 10px' }}><a href="https://www.facebook.com/share/qbW6m5DnVgDhRS53/"><i className="fa fa-facebook"></i></a></li>
                                    <li style={{ margin: '0 10px' }}><a href="https://www.instagram.com/ganeshtechnologysolutions/profilecard/?igsh=MXN3ODEwYmF4anV5bQ=="><i className="fa fa-instagram"></i></a></li>
                                    <li style={{ margin: '0 10px' }}><a href="https://wa.me/+918830415943"><i className="fa fa-whatsapp"></i></a></li>
                                    <li style={{ margin: '0 10px' }}><a href="https://www.linkedin.com/company/ganesh-technology-solutions/"><i className="fa fa-linkedin"></i></a></li>
                                </ul>
                            </div>
                        </div>

                        {/* Contact Info Section */}
                        <div className="col-lg-4 col-md-12 col-sm-12 footer-widget text-center">
                            <h4 className="widget-title">Contact Info</h4>
                            <ul className="address-widget" style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                                <li style={{ marginBottom: '10px' }}>
                                    <i className="flaticon-location"></i>
                                    <div className="desc">607/608 New Mhalgi Nagar, Nagpur.</div>
                                </li>
                                <li style={{ marginBottom: '10px' }}>
                                    <i className="flaticon-call"></i>
                                    <div className="desc">
                                        <a href="tel:+918830415943">+918830415943</a>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-clock"></i>
                                    <div className="desc">
                                        9:00 am - 9:00 pm
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Footer Bottom Section */}
                <div className="footer-bottom" style={{ textAlign: 'center' }}>
                    <div className="row y-middle align-items-center">
                        <div className="col-lg-12 col-md-12 sm-mb-21">
                            <div className="copyright">
                                <p>© 2024 Ganesh Technology Solutions. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>{`
                @media (max-width: 767px) {
                    .footer-content {
                        padding-top: 30px;
                        padding-bottom: 40px;
                    }

                    .footer-container {
                        width: 100%;
                    }

                    .footer-content .row {
                        flex-direction: column;
                    }

                    .footer-widget {
                        margin-bottom: 20px;
                    }

                    
                }
            `}</style>
        </div>
    );
}

export default Footer;
